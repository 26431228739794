<template>
  <div class="user-data">
    <div class="user-data__header">
      <div class="user-data__header-left">
        <div class="user-data__header-name">
          <UserpicBase />
          <div class="user-data__header-name-right">
            <h3>{{ fullName }}</h3>
            <p>
              ID: <span>{{ getProfile.id }}</span>
            </p>
          </div>
        </div>
        <div v-if="edit" class="header__control-block">
          <ButtonBase :loading="uploadLoader" @click="uploadPhoto"
            >Upload photo<input
              id="upload__photo"
              type="file"
              class="input"
              accept="image/*"
              multiple="false"
              @change="handlePhotoInput"
          /></ButtonBase>
          <ButtonBase :loading="deleteLoader" transparent @click="deletePhoto"
            >Delete photo</ButtonBase
          >
        </div>
      </div>
      <div class="dashboard__qr">
        <DashboardQR />
      </div>
    </div>
    <div class="user-data__content">
      <div class="content__rank">
        <svg-icon name="profile_rank"></svg-icon>
        <h5>Member Rank</h5>
        <p class="content__number">{{ getProfile.affiliate_rank }}</p>
      </div>
      <div class="content__level">
        <svg-icon name="profile_next"></svg-icon>
        <h5>Referrals Needed for Level {{ getProfile.next_affiliate_rank }}</h5>
        <p class="content__number">
          {{ getProfile.referrals_needed_count_for_next_rank }}
        </p>
      </div>
      <div class="content__referrals">
        <svg-icon name="profile_total"></svg-icon>
        <h5>Total Referrals</h5>
        <p class="content__number">{{ getProfile.total_referrals_count }}</p>
      </div>
      <div class="content__referral-id">
        <div class="content__referral-id-title">
          <svg-icon name="profile_id"></svg-icon>
          <h5>My referral ID</h5>
        </div>
        <div class="content__referral-id-number">
          <h5 id="content__referral-id-code" @click="copyURL">
            {{ getProfile.referral_code }}
            <span>Copy link</span>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserpicBase from '@/elements/UserpicBase.vue';
import DashboardQR from '@/components/Profile/DashboardQR.vue';

export default {
  components: { UserpicBase, DashboardQR },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deleteLoader: false,
      uploadLoader: false,
    };
  },
  computed: {
    ...mapGetters(['getProfile']),
    fullName() {
      let fullName =
        this.getProfile.first_name + ' ' + this.getProfile.last_name;
      if (this.edit && fullName.length > 14) {
        return fullName.slice(0, 14) + '...';
      } else {
        return fullName;
      }
    },
  },
  methods: {
    async copyURL() {
      try {
        await navigator.clipboard.writeText(this.getProfile.referral_link);
        this.$store.dispatch('setNotification', {
          type: 'success',
          title: `Copied to clipboard`,
          text: ``,
        });
      } catch ($e) {
        alert('Cannot copy');
      }
    },
    async deletePhoto() {
      this.deleteLoader = true;
      await this.$store.dispatch('deletePhoto');
      this.deleteLoader = false;
    },
    async uploadPhoto() {
      document.getElementById('upload__photo').click();
    },
    async handlePhotoInput(e) {
      this.uploadLoader = true;
      let formData = new FormData();
      formData.append('image', e.target.files[0]);
      await this.$store.dispatch('uploadPhoto', formData);
      this.uploadLoader = false;
    },
  },
};
</script>

<style lang="sass" scoped>
.user-data
  flex: 1 0 auto
  display: flex
  flex-direction: column
  position: relative
  background-color: $background-profile-color
  padding: 2.4rem
  border-radius: 8px
  gap: 2.4rem
  @include xl
    gap: 4rem
    padding: 1.6rem

  // @include m
  //   padding: 2.4rem

.user-data__header
  display: flex
  gap: 3.4rem
  align-items: center
  flex-grow: 1

.user-data__header-left
  display: flex
  flex-grow: 1
  gap: 3.4rem
  align-items: center
  justify-content: start
  @include xl
    flex-direction: column
    gap: 3rem

.user-data__header-name
  display: flex
  gap: 2.4rem
  align-items: center
  margin-right: auto
  @include m
    flex-direction: column
    justify-content: center
    margin: 0 auto
    gap: 1.2rem
    & h3
      font-size: 1.6rem
      line-height: 2.4rem
  & p
    background-color: $background-profile-darker-color
    border-radius: 4px
    font-size: 1.6rem
    line-height: 2.4rem
    font-weight: 400
    padding: 0.8rem 1.2rem
    & span
      font-size: 1.8rem
      font-weight: 500

.user-data__header-name-right
  display: flex
  // gap: 2.4rem
  align-items: flex-start
  // @include xl
  flex-direction: column
  gap: 1rem
  @include m
    gap: 1.2rem
    align-items: center
.user-data__content
  display: grid
  grid-template-columns: 1fr 2fr
  gap: 1.8rem 2.4rem
  @include m
    grid-template-columns: 1fr

.content__rank,.content__level,.content__referrals,.content__referral-id,.content__referral-long
  display: flex
  gap: 1.2rem
  flex-wrap: wrap
  align-items: center
  & svg
    max-width: 2.4rem
    max-height: 2.4rem

.content__referrals
  grid-row: 2/3

.content__referral-id-title
  display: flex
  gap: 1.2rem

.content__referral-long
  @include ms
    flex-direction: column

.content__referral-long
  @include l
    order: 1

.content__number
  background-color: $background-profile-darker-color
  padding: 0.6rem 1rem
  border-radius: 4px
  font-size: 1.6rem
  font-weight: 600

.content__referral-id-number
  background-color: #D81EFD14
  padding: 1rem 1rem
  border-radius: 6px
  cursor: pointer
  & h5
    color: rgba(216, 30, 253, 0.8)
    font-size: 1.6rem
    font-weight: 400
    & span
      font-size: 1.8rem
      margin-left: 1rem
      font-weight: 500
      color: rgba(216, 30, 253, 1)
      @include m
        font-size: 1.2rem
  @include m
  padding: 0.4rem 1rem
  & h5
    font-size: 1.2rem

.header__control-block
  display: flex
  gap: 2.4rem
  margin-left: auto
  @include xl
    margin-left: 0
    margin-right: auto
  @include m
    margin: 0 auto
    gap: 1.2rem

.input
  display: none

.dashboard__qr
  margin-left: auto
  display: none
  @include xl
    display: block
  @include m
    display: none
</style>
