<template>
  <div class="user-qr">
    <h5>My referral QR</h5>
    <div class="dashboard-qr" @click="openQR" v-html="getReferralQR"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {},
  computed: {
    ...mapGetters(['getReferralQR', 'getWindowWidth']),
  },
  methods: {
    openQR() {
      if (this.getWindowWidth < 767) {
        this.$store.commit('SET_QR_IS_OPEN', true);
        // this.$store.commit('modal/SET_MODAL', {
        //   state: true,
        //   name: 'qr',
        // });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.user-qr
  background-color: $background-profile-color
  border-radius: 8px
  padding: 2.4rem 2.4rem 4rem
  height: 100%
  display: flex
  flex-direction: column
  gap: 2.4rem
  // justify-content: space-between
  align-items: center
  @include xl
    background-color: transparent
    padding: 0
    flex-direction: column-reverse
    gap: 0.6rem
    & h5
      margin-top: 0.6rem
  & div
    width: 15.6rem
    height: 15.6rem
    @include xl
      width: 10rem
      height: 10rem
  @include m
    flex-direction: column
    gap: 1.2rem
    background-color: $background-profile-color
    padding: 2.4rem 2.4rem
    & div
      margin: 0 auto
      width: 15.6rem
      height: 15.6rem
</style>
