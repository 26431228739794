<template>
  <div class="dashboard__user-balance">
    <div class="user-balance">
      <h3 class="user-balance__title">Balance</h3>
      <div class="user-balance__items">
        <div v-if="getProfile.bsc_receive_address" class="user-balance__item">
          <div class="user-balance__item-info">
            <svg-icon name="bsc"></svg-icon>
            <h5 class="user-balance__item-title">BNB</h5>
            <span class="user-balance__item-value">
              {{ formatBSCBalance(getProfile.bsc_balance) }}
            </span>
            <button
              class="user-balance__item-btn"
              @click="updateBalance('bsc', $event)"
            >
              <svg-icon name="refresh" />
            </button>
          </div>
          <div
            class="user-balance__item-copy"
            @click="copyWalletAddress('bsc_receive_address')"
          >
            <h5 class="user-balance__item-copy-address">
              {{ formatWalletAddress(getProfile.bsc_receive_address) }}
              <span>Copy</span>
            </h5>
          </div>
        </div>

        <div
          v-if="getProfile.cosmos_receive_address"
          class="user-balance__item"
        >
          <div class="user-balance__item-info">
            <svg-icon name="cosmos"></svg-icon>
            <h5 class="user-balance__item-title">Cosmos</h5>
            <span class="user-balance__item-value">
              {{ formatKeplrBalance(getProfile.cosmos_balance) }}
            </span>
            <button
              class="user-balance__item-btn"
              @click="updateBalance('cosmos', $event)"
            >
              <svg-icon name="refresh" />
            </button>
          </div>
          <div
            class="user-balance__item-copy"
            @click="copyWalletAddress('cosmos_receive_address')"
          >
            <h5 class="user-balance__item-copy-address">
              {{ formatWalletAddress(getProfile.cosmos_receive_address) }}
              <span>Copy</span>
            </h5>
          </div>
        </div>

        <div v-if="getProfile.srcx_receive_address" class="user-balance__item">
          <div class="user-balance__item-info">
            <svg-icon name="srcx_address"></svg-icon>
            <h5 class="user-balance__item-title">SRCX</h5>
            <span class="user-balance__item-value">
              {{ formatSRCXBalance(getProfile.srcx_balance) }}
            </span>
            <button
              class="user-balance__item-btn"
              @click="updateBalance('srcx', $event)"
            >
              <svg-icon name="refresh" />
            </button>
          </div>
          <div
            class="user-balance__item-copy"
            @click="copyWalletAddress('srcx_receive_address')"
          >
            <h5 class="user-balance__item-copy-address">
              {{ formatWalletAddress(getProfile.srcx_receive_address) }}
              <span>Copy</span>
            </h5>
          </div>
        </div>

        <div
          v-if="getProfile.source_receive_address"
          class="user-balance__item"
        >
          <div class="user-balance__item-info">
            <svg-icon name="source_address"></svg-icon>
            <div class="user-balance__item-wrapper">
              <h5 class="user-balance__item-title">Source</h5>
            </div>
            <span class="user-balance__item-value">
              {{ formatKeplrBalance(getProfile.source_balance) }}
            </span>
            <button
              class="user-balance__item-btn"
              @click="updateBalance('source', $event)"
            >
              <svg-icon name="refresh" />
            </button>
          </div>
          <div
            class="user-balance__item-copy"
            @click="copyWalletAddress('source_receive_address')"
          >
            <h5 class="user-balance__item-copy-address">
              {{ formatWalletAddress(getProfile.source_receive_address) }}
              <span>Copy</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Web3 from 'web3';

export default {
  computed: {
    ...mapGetters(['getProfile', 'getWindowWidth']),
  },
  methods: {
    formatWalletAddress(address) {
      const startIndex = this.getWindowWidth > 500 ? 8 : 22;

      return address
        ? address.slice(0, startIndex) + '...' + address.slice(-4)
        : 'Not filled';
    },
    async copyWalletAddress(walletName) {
      if (this.getProfile[walletName]) {
        await navigator.clipboard.writeText(this.getProfile[walletName]);
        this.$store.dispatch('setNotification', {
          type: 'success',
          title: `Copied to clipboard`,
          text: ``,
        });
      } else {
        this.$router.push({ name: 'settings' });
      }
    },
    async updateBalance(walletName, event) {
      const refreshButton = event.currentTarget;

      refreshButton.classList.add('animate');
      await this.$store.dispatch('updateWalletBalance', walletName);
      refreshButton.classList.remove('animate');
    },
    formatBSCBalance(balance) {
      const web3 = new Web3(Web3.givenProvider);
      const normalizedBalance = web3.utils.fromWei(`${balance}`, 'ether');
      let formattedBalance = parseFloat(normalizedBalance);

      return formattedBalance > 0
        ? formattedBalance.toFixed(4)
        : formattedBalance;
    },
    formatSRCXBalance(balance) {
      return balance / 1000000000;
    },
    formatKeplrBalance(balance) {
      return balance / 1000000;
    },
  },
};
</script>

<style lang="sass" scoped>
.user-balance
  position: relative
  background-color: rgba(255, 255, 255, 0.1)
  padding: 2.4rem
  border-radius: 8px
  @include m
    margin-top: 2.4rem

.user-balance__title
  margin-bottom: 3.2rem
  @include ms
    margin-bottom: 2rem

.user-balance__items
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr))
  gap: 3.2rem 6.4rem
  @media screen and (min-width: 1920px)
    grid-template-columns: repeat(4, 1fr)
    gap: 3.2rem 8rem
  @include xxl
    gap: 3.2rem 2.4rem
  @include ms
    gap: 2rem 0

.user-balance__item
  display: flex
  flex-direction: column
  justify-content: space-between
  gap: 1.2rem
  & svg
    max-width: 2.4rem
    max-height: 2.4rem

  @include ms
    gap: 1.6rem 0

.user-balance__item-info
  display: flex
  align-items: center
  gap: 1.2rem
  @include ms
    gap: 1rem

.user-balance__item:last-child
  .user-balance__item-info
    position: relative
    top: -7px

.user-balance__item-wrapper
  display: flex
  flex-direction: column

.user-balance__item-wrapper span
  font-weight: 500
  @include adaptive-font-size(18, 16, 14)
  @include adaptive-line-height(26, 24, 21)
  @include m
    font-weight: 400

.user-balance__item-value
  display: flex
  background-color: $background-profile-darker-color
  padding: 0.6rem 1rem
  border-radius: 4px
  @include adaptive-font-size(16, 16, 12)
  @include adaptive-line-height(24, 24, 18)
  font-weight: 600

.user-balance__item-btn
  display: flex

  svg
    max-width: 2rem
    max-height: 2rem

.user-balance__item-btn.animate
  animation: rotateButton 1s linear infinite

@keyframes rotateButton
  from
    transform: rotate(0)
  to
    transform: rotate(360deg)

.user-balance__item-copy
  background-color: rgba(216, 30, 253, 0.08)
  padding: 0.8rem 1.2rem
  border-radius: 6px
  cursor: pointer
  @include ms
    padding: 0.4rem 1.2rem

.user-balance__item-copy-address
  display: flex
  align-items: center
  justify-content: space-between
  color: rgba(216, 30, 253, 0.8)
  @include adaptive-font-size(16, 16, 12)
  @include adaptive-line-height(24, 24, 18)
  font-weight: 400
  span
    @include adaptive-font-size(18, 18, 12)
    @include adaptive-line-height(26, 26, 18)
    margin-left: 1.2rem
    font-weight: 500
    color: rgba(216, 30, 253, 1)
    @include ms
      font-size: 1.2rem
      line-height: 1.8rem
</style>
