<template>
  <label class="input__photo-label" :for="photoID">
    <div
      :class="{ editable, table }"
      class="userpic"
      :style="{ background: background }"
    >
      <div v-if="editable" class="userpic-upload">
        <svg-icon name="upload_photo"></svg-icon>
        <div class="upload_photo__filter"></div>
        <input
          id="upload__photo"
          type="file"
          class="input"
          accept=""
          multiple="false"
          hidden
          @change="handlePhotoInput"
        />
        {{ $t('main.upload_photo') }}
      </div>
      <div v-if="table && photoUrl">
        <img :src="photoUrl" alt="userpic" />
      </div>
      <div v-else-if="table && !photoUrl" class="userpic-empty">
        <div class="userpic-empty__text" :class="{ table }">
          {{ initials }}
        </div>
      </div>
      <div v-else-if="!getProfile.photo_url" class="userpic-empty">
        <div class="userpic-empty__text" :class="{ table }">
          {{ initials }}
        </div>
      </div>
      <div v-else class="userpic-uploaded" :class="{ table }">
        <img :src="getProfile.photo_url" alt="userpic" />
      </div>
    </div>
  </label>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Boolean,
      default: false,
    },
    photoUrl: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['getProfile']),
    photoID() {
      return this.table ? '' : 'upload__photo';
    },
    initials() {
      if (!this.table) {
        return `${this.getProfile.first_name.slice(
          0,
          1
        )}${this.getProfile.last_name.slice(0, 1)}`;
      } else {
        return `${this.firstName.slice(0, 1)}${this.lastName.slice(0, 1)}`;
      }
    },
    background() {
      return `linear-gradient(${Math.floor(
        Math.random() * 360
      )}deg, rgba(180,67,244,1) ${
        Math.floor(Math.random() * (30 - 0)) + 0
      }%, rgba(72,34,143,1) ${
        Math.floor(Math.random() * (60 - 40)) + 40
      }%, rgba(66,139,227,1) ${Math.floor(Math.random() * (100 - 60)) + 60}%)`;
    },
  },
  created() {},
  methods: {
    async handlePhotoInput(e) {
      let formData = new FormData();
      formData.append('image', e.target.files[0]);
      await this.$store.dispatch('updatePhoto', formData);
    },
  },
};
</script>

<style lang="sass" scoped>
.userpic
  position: relative
  border-radius: 50%
  width: 8rem
  height: 8rem
  background-color: #E0F4F4
  overflow: hidden
  // background-image: url(/images/userpic_bg.png)
  background-size: cover
  // background: linear-gradient(283deg, rgba(1,242,207,1) 0%, rgba(218,3,217,1) 0%, rgba(3,179,218,1) 0%, rgba(180,67,244,1) 25%, rgba(72,34,143,1) 43%, rgba(66,139,227,1) 81%)
  background-position: center
  // background: radial-gradient(circle, rgba(66,139,227,1) 58%, rgba(72,34,143,1) 69%, rgba(180,67,244,1) 75%)

.table
  width: 4rem
  height: 4rem

.userpic.editable
  cursor: pointer

.userpic-upload
  color: $white
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 2
  text-align: center
  font-family: $primary-font
  font-size: 1.3rem
  line-height: 1.6rem

.upload_photo__filter
  width: 12rem
  height: 12rem
  background-color: #14364C
  opacity: 0.5
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: -1

.userpic-empty__text
  position: absolute
  text-align: center
  // padding: 0.8rem 0
  // color: $text-secondary-color
  top: 50%
  left: 50%
  transform: translate(-50%, -45%)
  font-weight: 700
  font-size: 3rem
  cursor: default

.userpic-empty__text.table
  font-size: 2rem
  // display: none
  top: 68%

.userpic-uploaded
  width: calc(100% - 4px)
  height: calc(100% - 4px)
  border-radius: 50%
  // border: 2px solid $text-third-color
  // outline: 2px solid $text-secondary-color
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  & img
    border-radius: 50%

.userpic-uploaded.table
  outline: none
  border: 2px solid #D8D8FE
  width: calc(100% - 2px)
  height: calc(100% - 2px)
</style>
